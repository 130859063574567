<template>
    <div class="coupon-card-info-container" :style="{height:scrollHeight+'px'}">
        <img style="width: 100%;" :src="couponCardInfo.poster_path"/>
        <div class="share" @click="share">
            <div class="share-text">
                <span>分享好友</span>
                <span>秒赚佣金</span>
            </div>

        </div>
        <div style="height: 44px; width: 100%;"></div>
        <div class="footer" @click="goToBuy">
            <div class="button">立即购买</div>
        </div>
    </div>
</template>

<script>
  import RsckLoading from '@/components/common/rsck-loading'

  export default {
    name: 'card-buy',
    components: {RsckLoading},
    mounted () {
      document.title = '礼品卡详情'
      let query = this.$route.query
      this.id = query.id || 0
      this.getCouponCardInfo()
      this.isLoading = false
      this.canContinue = true
    },
    data () {
      return {
        id: 0,
        couponCardInfo: {},
        isLoading: false,
        canContinue: false,
        scrollHeight: window.innerHeight
      }
    },
    methods: {
      getCouponCardInfo () {
        this.$api.couponCard.couponCardInfo({id: this.id}).then(response => {
          this.couponCardInfo = response.data
        })
      },
      share () {
        this.canContinue = false
        let query = {
          id: this.id,
        }
        let options = encodeURIComponent(JSON.stringify(query))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/share/share-card?query=' + options,
        })
        setTimeout(() => {
          this.canContinue = true
        })
      },
      goToBuy () {
        let query = {
          card_id: this.id,
          number: 1
        }
        let options = encodeURIComponent(JSON.stringify(query))

        if (this.canContinue) {
          this.canContinue = false
          this.$platform.wxsdk.wxRoute({
            type: 'redirectTo',
            url: '/web/couponCard/card-buy-confirm?query=' + options,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    .coupon-card-info-container {
        width: 100vw;
        min-height: 100vh;
        background: #f6f6f6;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position: relative;

        .share {
            background: url('https://api.mengjingloulan.com/storage/wechat/shopping/swiperShare.png') 0 0 no-repeat;
            background-size: cover;
            width: 126px;
            height: 45px;
            position: fixed;
            top: 310px;
            right: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .share-text {
                span {
                    width: 100%;
                    display: block;
                    text-indent: 27px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }

        }

        .footer {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            height: 58px;
            background: #fff;
            border-top: #f6f6f6 solid 1px;
            padding: 0 15px;
            display: flex;
            align-items: center;

            .button {
                width: 100%;
                height: 44px;
                background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                -webkit-border-radius: 6px;
                -moz-border-radius: 6px;
                border-radius: 6px;
                color: #ffffff;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>